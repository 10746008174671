<template>
  <c-form-control
    :is-disabled="isDisabled"
    :is-required="isRequired"
    :is-invalid="isInvalid"
    :margin-bottom="isWithoutMarginBottom ? '0' : SIZE.other.marginBottom"
    :width="fullWidth ? '100%' : 'inherit'"
  >
    <c-form-label
      v-if="!!label"
      :font-size="SIZE.label.fontSize"
      :color="isInvalid ? 'danger.400' : value ? 'primary.400' : '#555'"
      font-weight="400"
      font-family="Roboto"
      padding-left="8px"
    >
      {{ label }}
    </c-form-label>
    <c-select
      v-model="modelValue"
      class="base-input-select__select"
      :height="SIZE.input.height"
      :font-size="SIZE.input.fontSize"
      :placeholder="placeholder ? placeholder : label"
      :color="isInvalid ? 'danger.400' : modelValue ? 'primary.400' : '#A0AEC0'"
      font-weight="500"
      focus-border-color="primary.400"
      border-radius="8px"
    >
      <option
        v-if="options.length === 0 && modelValue"
        :key="modelValue"
        :value="modelValue"
        style="color: #555;"
      >
        {{ modelValue }}
      </option>
      <option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
        style="color: #555;"
      >
        {{ option.label }}
      </option>
    </c-select>

    <c-form-helper-text
      v-if="!isDataEmpty(invalidText) && isInvalid"
      :font-size="SIZE.helperText.fontSize"
      :font-weight="SIZE.helperText.fontWeight"
      display="flex"
      flex-direction="column"
      gap="8px"
      color="#D32737"
    >
      <c-text
        v-for="(invalidTextValue, invalidTextKey) in invalidText_"
        :key="invalidTextKey"
      >
        &#9679; {{ invalidTextValue }}
      </c-text>
    </c-form-helper-text>
  </c-form-control>
</template>

<script>
import { isDataEmpty } from '@/utils/is-data-empty'

export default {
  name: 'BaseInputSelect',
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    invalidText: {
      type: [String, Object],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    options: {
      type: Array,
      default: () => ([]),
    },
    isWithoutMarginBottom: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    size: {
      type: [String, Array],
      default: () => ['sm', 'md'],
    },
  },
  emits: [
    'input',
    'trigger-change', // TODO: add example
    'blur', // TODO: add example
  ],
  computed: {
    SIZE() {
      const CONST_SIZE = {
        sm: {
          other: {
            marginBottom: '16px',
          },
          label: {
            fontSize: '14px',
          },
          input: {
            height: '48px',
            fontSize: '14px',
          },
          helperText: {
            fontSize: '12px',
            fontWeight: '400',
          },
        },
        md: {
          other: {
            marginBottom: '24px',
          },
          label: {
            fontSize: '16px',
          },
          input: {
            height: '62px',
            fontSize: '18px',
          },
          helperText: {
            fontSize: '16px',
            fontWeight: '400',
          },
        },
      }

      if (typeof this.size === 'string') {
        return CONST_SIZE[this.size]
      }
      return {
        other: {
          marginBottom: this.size.map((s) => CONST_SIZE[s].other.marginBottom),
        },
        label: {
          fontSize: this.size.map((s) => CONST_SIZE[s].label.fontSize),
        },
        input: {
          height: this.size.map((s) => CONST_SIZE[s].input.height),
          fontSize: this.size.map((s) => CONST_SIZE[s].input.fontSize),
        },
        helperText: {
          fontSize: this.size.map((s) => CONST_SIZE[s].helperText.fontSize),
          fontWeight: this.size.map((s) => CONST_SIZE[s].helperText.fontWeight),
        },
      }
    },
    invalidText_() {
      if (typeof this.invalidText === 'string') {
        return [this.invalidText]
      }
      return this.invalidText
    },
    modelValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
        this.$emit('trigger-change', val)
        this.$emit('blur')
      },
    },
  },
  methods: {
    isDataEmpty,
  },
}
</script>

<style scoped>
.base-input-select__select::v-deep option {
  color: #555;
}

.base-input-select__select::v-deep select[value=""] {
  color: #A0AEC0;
}
</style>
