var render = function () {
  var _vm$$route$query$perp, _vm$$route$query, _vm$$route$query2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "flex-grow": "1"
    }
  }, [_c('BreadcrumbPath', {
    attrs: {
      "margin-bottom": "20px",
      "paths": [{
        label: 'Manajemen Panduan Diet',
        href: '/admin/coaching-challenge'
      }, {
        label: 'Kategori',
        isCurrent: true
      }]
    }
  }), _c('c-box', {
    attrs: {
      "width": "100%",
      "max-width": "1270px",
      "background-color": "#FFF",
      "margin-bottom": "16px",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "padding": ['16px', '30px'],
      "min-height": ['unset', '74vh'],
      "display": "flex",
      "flex-direction": "column",
      "align-items": "center"
    }
  }, [_c('c-flex', {
    attrs: {
      "mb": "30px",
      "justify": "space-between",
      "align": "center",
      "width": "100%"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "20px",
      "size-desktop": "28px"
    }
  }, [_vm._v(" Kategori ")]), _c('c-flex', {
    attrs: {
      "gap": "3"
    }
  }, [_c('BaseButton', {
    attrs: {
      "left-svg-icon": require('@/assets/icons/icon-plus.svg'),
      "left-svg-icon-color": "#008C81",
      "border-radius": "16px",
      "variant": "outlined"
    },
    on: {
      "click": _vm.onOpenModalSubCategory
    }
  }, [_vm._v(" Tambah Sub Kategori ")]), _c('BaseButton', {
    attrs: {
      "left-svg-icon": require('@/assets/icons/icon-plus.svg'),
      "left-svg-icon-color": "white",
      "border-radius": "16px"
    },
    on: {
      "click": _vm.onOpenModalAddCategory
    }
  }, [_vm._v(" Tambah Kategori ")])], 1)], 1), _c('ContentType', {
    attrs: {
      "type": _vm.type
    },
    on: {
      "click": function click(value) {
        return _vm.onChangeType(value);
      }
    }
  }), _c('c-flex', {
    attrs: {
      "margin-bottom": "16px",
      "gap": "16px",
      "width": "100%",
      "align-items": "center"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "18px",
      "size-desktop": "20px",
      "white-space": "nowrap"
    }
  }, [_vm._v(" List of Kategori ")]), _c('c-box', {
    attrs: {
      "width": "100%"
    }
  }, [_c('c-form-control', {
    attrs: {
      "pos": "relative",
      "w": "100%"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/ic-search.svg'),
      "alt": "icon",
      "position": "absolute",
      "top": "50%",
      "transform": "translateY(-50%)",
      "left": "15px",
      "height": "24px",
      "z-index": "2"
    }
  }), _c('c-input', {
    attrs: {
      "id": "search",
      "type": "text",
      "w": "100%",
      "height": "62px",
      "placeholder": "Pencarian",
      "border-radius": "20px",
      "padding-left": "50px",
      "position": "relative",
      "z-index": "1"
    },
    on: {
      "keyup": _vm.onSearch
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1)], 1)], 1), _vm.isLoading ? _c('c-box', [_vm._v(" loading... ")]) : _vm.category.length <= 0 ? _c('c-flex', {
    attrs: {
      "w": "100%",
      "flex-direction": "column",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_c('c-image', {
    attrs: {
      "src": "https://ik.imagekit.io/dietela/pwa_webp/content_challenge/undraw_empty_re_opql%202.webp?updatedAt=1695810504720",
      "width": "300px",
      "mt": "3rem",
      "mb": "1rem"
    }
  }), _c('c-text', {
    attrs: {
      "mb": "1rem"
    }
  }, [_vm._v(" Kategori belum ada, silahkan tambahkan kategori. ")])], 1) : _c('c-box', {
    attrs: {
      "background": "#FFFFFF",
      "border": "1px solid #F2F2F2",
      "box-shadow": "4px 4px 50px 5px rgba(0, 0, 0, 0.05)",
      "border-radius": "12px",
      "width": "100%"
    }
  }, [_c('c-box', {
    staticClass: "base-table__table",
    attrs: {
      "id": "d-table",
      "as": "table",
      "width": "100%",
      "border": "0",
      "cellspacing": "0",
      "cellpadding": "0"
    }
  }, [_c('c-box', {
    staticClass: "base-table__thead",
    attrs: {
      "as": "thead",
      "color": "primary.400",
      "background-color": "primary.50"
    }
  }, [_c('c-box', {
    staticClass: "base-table__tr",
    attrs: {
      "as": "tr"
    }
  }, [_c('c-box', {
    class: {
      'base-table__th': true
    },
    attrs: {
      "as": "th",
      "font-size": "14px",
      "font-weight": "400",
      "font-family": "Roboto, sans-serif, Arial",
      "padding": "12px 16px",
      "text-align": 'column.align' || 'left'
    }
  }, [_c('c-box', {
    attrs: {
      "width": 'column.width' || '100%',
      "display": "flex",
      "align-items": "center",
      "gap": "8px"
    }
  }, [_c('span', [_vm._v(_vm._s('NO'))])])], 1), _c('c-box', {
    class: {
      'base-table__th': true
    },
    attrs: {
      "as": "th",
      "font-size": "14px",
      "font-weight": "400",
      "font-family": "Roboto, sans-serif, Arial",
      "padding": "12px 16px",
      "text-align": 'column.align' || 'left'
    }
  }, [_c('c-box', {
    attrs: {
      "width": 'column.width' || '100%',
      "display": "flex",
      "align-items": "center",
      "gap": "8px"
    }
  }, [_c('span', [_vm._v(_vm._s('KATEGORI'))])])], 1), _c('c-box', {
    class: {
      'base-table__th': true
    },
    attrs: {
      "as": "th",
      "font-size": "14px",
      "font-weight": "400",
      "font-family": "Roboto, sans-serif, Arial",
      "padding": "12px 16px",
      "text-align": 'column.align' || 'left'
    }
  }, [_c('c-box', {
    attrs: {
      "width": 'column.width' || '100%',
      "display": "flex",
      "align-items": "center",
      "gap": "8px"
    }
  }, [_c('span', [_vm._v(_vm._s('AKSI'))])])], 1)], 1)], 1), _vm._l(_vm.category, function (item, itemIndex) {
    var _item$subCategories, _item$subCategories2;

    return _c('tr', {
      key: item.id,
      staticStyle: {
        "position": "sticky"
      }
    }, [_c('td', {
      staticStyle: {
        "vertical-align": "top"
      }
    }, [_c('c-text', {
      attrs: {
        "padding": "8px 16px"
      }
    }, [_vm._v(" " + _vm._s(_vm.meta.pagesize * (_vm.meta.page - 1) + itemIndex + 1) + " ")])], 1), _c('td', {
      staticStyle: {
        "width": "100%"
      }
    }, [(item === null || item === void 0 ? void 0 : (_item$subCategories = item.subCategories) === null || _item$subCategories === void 0 ? void 0 : _item$subCategories.length) >= 1 ? _c('c-accordion', {
      attrs: {
        "allow-multiple": true,
        "default-is-open": false
      }
    }, [_c('c-accordion-item', {
      attrs: {
        "border": "none"
      }
    }, [_c('c-accordion-header', [_c('c-box', {
      attrs: {
        "flex": "1",
        "text-align": "left"
      }
    }, [_c('c-text', [_vm._v(_vm._s(item.categoryName || '-'))])], 1), _c('c-accordion-icon')], 1), _c('c-accordion-panel', {
      attrs: {
        "padding": "0"
      }
    }, [_c('c-box', {
      attrs: {
        "height": ((item === null || item === void 0 ? void 0 : (_item$subCategories2 = item.subCategories) === null || _item$subCategories2 === void 0 ? void 0 : _item$subCategories2.length) || 0) * 43
      }
    }), _c('table', {
      staticStyle: {
        "width": "100%",
        "position": "absolute",
        "top": "43px",
        "left": "0"
      },
      attrs: {
        "id": "d-table-nested"
      }
    }, _vm._l(item === null || item === void 0 ? void 0 : item.subCategories, function (subCategory) {
      return _c('tr', {
        key: subCategory.id
      }, [_c('td', {
        staticStyle: {
          "width": "100%",
          "padding-left": "80px"
        }
      }, [_c('c-text', [_vm._v(" • " + _vm._s(subCategory.subCategoryName || '-') + " ")])], 1), _c('td', [_c('c-flex', [_c('c-button', {
        attrs: {
          "variant-color": "primary",
          "variant": "ghost"
        },
        on: {
          "click": function click($event) {
            return _vm.onOpenModalUpdateSubCategory(item, subCategory);
          }
        }
      }, [_c('inline-svg', {
        attrs: {
          "src": require('@/assets/icons/icon-edit-2.svg'),
          "height": "25",
          "width": "25",
          "fill": "#008C81"
        }
      })], 1), _c('c-button', {
        attrs: {
          "variant-color": "danger",
          "variant": "ghost"
        },
        on: {
          "click": function click($event) {
            return _vm.onOpenModalDelete('sub-category', subCategory);
          }
        }
      }, [_c('inline-svg', {
        attrs: {
          "src": require('@/assets/icons/icon-trash.svg'),
          "height": "21",
          "width": "21",
          "fill": "#D32737"
        }
      })], 1)], 1)], 1)]);
    }), 0)], 1)], 1)], 1) : _c('c-text', {
      attrs: {
        "padding": "8px 16px"
      }
    }, [_vm._v(" " + _vm._s(item.categoryName || '-') + " ")])], 1), _c('td', {
      staticStyle: {
        "vertical-align": "top"
      }
    }, [_c('c-flex', [_c('c-button', {
      attrs: {
        "variant-color": "primary",
        "variant": "ghost"
      },
      on: {
        "click": function click($event) {
          return _vm.onOpenModalUpdateCategory(item);
        }
      }
    }, [_c('inline-svg', {
      attrs: {
        "src": require('@/assets/icons/icon-edit-2.svg'),
        "height": "25",
        "width": "25",
        "fill": "#008C81"
      }
    })], 1), _c('c-button', {
      attrs: {
        "variant-color": "danger",
        "variant": "ghost"
      },
      on: {
        "click": function click($event) {
          return _vm.onOpenModalDelete('category', item);
        }
      }
    }, [_c('inline-svg', {
      attrs: {
        "src": require('@/assets/icons/icon-trash.svg'),
        "height": "21",
        "width": "21",
        "fill": "#D32737"
      }
    })], 1)], 1)], 1)]);
  })], 2), _c('c-flex', {
    attrs: {
      "justify": "space-between",
      "align": "center",
      "border-top": "2px solid #CBD5E0",
      "padding": "8px 16px"
    }
  }, [_c('c-box', [_c('c-flex', {
    attrs: {
      "align": "center"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "14px-2",
      "size-desktop": "16px"
    }
  }, [_vm._v(" Show ")]), _c('c-select', {
    attrs: {
      "min-width": "140px",
      "border-radius": "8px",
      "size": "md",
      "color": "primary.400",
      "font-size": "18px",
      "font-weight": "500",
      "margin-left": "16px",
      "value": ((_vm$$route$query$perp = _vm.$route.query.perpage) === null || _vm$$route$query$perp === void 0 ? void 0 : _vm$$route$query$perp.toString()) || '10'
    },
    on: {
      "change": function change(perPage_) {
        return _vm.handleChangePerpage(perPage_);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "5"
    }
  }, [_vm._v(" 5 Rows ")]), _c('option', {
    attrs: {
      "value": "10"
    }
  }, [_vm._v(" 10 Rows ")]), _c('option', {
    attrs: {
      "value": "15"
    }
  }, [_vm._v(" 15 Rows ")]), _c('option', {
    attrs: {
      "value": "20"
    }
  }, [_vm._v(" 20 Rows ")])])], 1)], 1), _c('c-box', {
    attrs: {
      "font-size": "14px",
      "font-weight": "400",
      "font-family": "Roboto, sans-serif, Arial",
      "padding": "12px 0",
      "display": "flex",
      "justify-content": "center",
      "align-items": "center",
      "position": "relative"
    }
  }, [_c('BasePagination', {
    attrs: {
      "page": (_vm$$route$query = _vm.$route.query) !== null && _vm$$route$query !== void 0 && _vm$$route$query.page ? parseInt((_vm$$route$query2 = _vm.$route.query) === null || _vm$$route$query2 === void 0 ? void 0 : _vm$$route$query2.page) : 1,
      "total-page": _vm.calculatePerPage(_vm.meta.total, _vm.meta.pagesize)
    },
    on: {
      "on-change": function onChange(page) {
        return _vm.handleChangePage(page);
      }
    }
  })], 1)], 1)], 1)], 1), _c('BaseModal', {
    attrs: {
      "is-open": _vm.isOpenModalCategory,
      "close-on-overlay-click": true,
      "with-button-close": true
    },
    on: {
      "close": _vm.onCloseModalCategory
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-text', {
          attrs: {
            "py": "1rem",
            "px": "1rem",
            "font-size": "22px"
          }
        }, [_vm._v(" " + _vm._s(_vm.selectedCategory ? 'Edit' : 'Tambah') + " Kategori ")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "px": "1rem"
          }
        }, [_c('BaseInputSelect', {
          attrs: {
            "label": "Tipe Konten",
            "placeholder": "Pilih Tipe Konten",
            "is-required": "",
            "is-disabled": _vm.selectedCategory ? true : false,
            "full-width": "",
            "options": _vm.types_
          },
          model: {
            value: _vm.formCategory.contentTypeId,
            callback: function callback($$v) {
              _vm.$set(_vm.formCategory, "contentTypeId", $$v);
            },
            expression: "formCategory.contentTypeId"
          }
        }), _c('BaseInputText', {
          attrs: {
            "label": "Kategori",
            "placeholder": "Masukkan Nama Kategori",
            "is-required": ""
          },
          model: {
            value: _vm.formCategory.categoryName,
            callback: function callback($$v) {
              _vm.$set(_vm.formCategory, "categoryName", $$v);
            },
            expression: "formCategory.categoryName"
          }
        }), _c('BaseLabel', {
          attrs: {
            "value": _vm.formCategory.image,
            "label": "Preview Cover Kategori",
            "is-required": ""
          }
        }, [_c('label', {
          directives: [{
            name: "chakra",
            rawName: "v-chakra",
            value: {
              bg: 'white',
              cursor: 'pointer',
              border: '1px solid #C4C4C4',
              boxSizing: 'border-box',
              borderRadius: '6px',
              w: '180px',
              h: '100%',
              minHeight: '180px',
              d: 'flex',
              p: '0',
              alignItems: 'center',
              justifyContent: 'center',
              overflow: 'hidden'
            },
            expression: "{\n              bg: 'white',\n              cursor: 'pointer',\n              border: '1px solid #C4C4C4',\n              boxSizing: 'border-box',\n              borderRadius: '6px',\n              w: '180px',\n              h: '100%',\n              minHeight: '180px',\n              d: 'flex',\n              p: '0',\n              alignItems: 'center',\n              justifyContent: 'center',\n              overflow: 'hidden',\n            }"
          }],
          attrs: {
            "for": "img-preview"
          }
        }, [_vm.formCategory.image ? _c('c-image', {
          attrs: {
            "src": _vm.getPreviewImage(_vm.formCategory.image),
            "h": !_vm.getPreviewImage(_vm.formCategory.image).includes('icon-photo') ? '100%' : '100px',
            "w": "100px",
            "alt": "img program",
            "mx": "auto",
            "border-radius": "6px"
          }
        }) : _c('inline-svg', {
          attrs: {
            "src": require('@/assets/icons/icon-image-preview.svg'),
            "height": "100px",
            "width": "100px",
            "fill": "#008C81"
          }
        })], 1), _c('c-input', {
          staticStyle: {
            "display": "none"
          },
          attrs: {
            "id": "img-preview",
            "type": "file",
            "accept": ".jpg, .jpeg, .png"
          },
          on: {
            "change": function change($event) {
              return _vm.onChangeCategoryImage($event);
            }
          }
        })], 1)], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "gap": "20px",
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin-bottom": "24px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "variant": "outlined",
            "rounded": "1000px",
            "width": "100%",
            "disabled": _vm.isLoadingModal
          },
          on: {
            "click": _vm.onCloseModalCategory
          }
        }, [_vm._v(" Batal ")]), _c('BaseButton', {
          attrs: {
            "color": "primary.400",
            "rounded": "1000px",
            "width": "100%",
            "is-loading": _vm.isLoadingModal,
            "disabled": _vm.isDisabledSubmitCategory
          },
          on: {
            "click": _vm.onOpenModalConfirmCategory
          }
        }, [_vm._v(" Simpan ")])], 1)];
      },
      proxy: true
    }])
  }), _c('BaseModal', {
    attrs: {
      "is-open": _vm.isOpenModalSubCategory,
      "close-on-overlay-click": false,
      "with-button-close": true
    },
    on: {
      "close": _vm.onCloseModalSubCategory
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-text', {
          attrs: {
            "py": "1rem",
            "px": "1rem",
            "font-size": "22px"
          }
        }, [_vm._v(" " + _vm._s(_vm.selectedCategory ? 'Edit' : 'Tambah') + " Sub Kategori ")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "px": "1rem"
          }
        }, [_c('BaseInputSelect', {
          attrs: {
            "label": "Tipe Konten",
            "placeholder": "Pilih Tipe Konten",
            "is-required": "",
            "is-disabled": _vm.selectedSubCategory ? true : false,
            "full-width": "",
            "options": _vm.types_
          },
          model: {
            value: _vm.formSubCategory.contentTypeId,
            callback: function callback($$v) {
              _vm.$set(_vm.formSubCategory, "contentTypeId", $$v);
            },
            expression: "formSubCategory.contentTypeId"
          }
        }), _c('BaseInputSelect', {
          attrs: {
            "label": "Kategori",
            "placeholder": "Pilih Kategori",
            "is-required": "",
            "is-disabled": false,
            "full-width": "",
            "options": _vm.categories_
          },
          model: {
            value: _vm.formSubCategory.categoryId,
            callback: function callback($$v) {
              _vm.$set(_vm.formSubCategory, "categoryId", $$v);
            },
            expression: "formSubCategory.categoryId"
          }
        }), _c('BaseInputText', {
          attrs: {
            "label": "Sub Kategori",
            "placeholder": "Masukkan Nama Sub Kategori",
            "is-required": ""
          },
          model: {
            value: _vm.formSubCategory.subCategoryName,
            callback: function callback($$v) {
              _vm.$set(_vm.formSubCategory, "subCategoryName", $$v);
            },
            expression: "formSubCategory.subCategoryName"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "gap": "20px",
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin-bottom": "24px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "variant": "outlined",
            "rounded": "1000px",
            "width": "100%",
            "disabled": _vm.isLoadingModal
          },
          on: {
            "click": _vm.onCloseModalSubCategory
          }
        }, [_vm._v(" Batal ")]), _c('BaseButton', {
          attrs: {
            "color": "primary.400",
            "rounded": "1000px",
            "width": "100%",
            "is-loading": _vm.isLoadingModal,
            "disabled": _vm.isDisabledSubmitSubCategory
          },
          on: {
            "click": _vm.onOpenModalConfirmSubCategory
          }
        }, [_vm._v(" Simpan ")])], 1)];
      },
      proxy: true
    }])
  }), _c('BaseModal', {
    attrs: {
      "is-open": _vm.isOpenModalConfirmCategory,
      "close-on-overlay-click": false,
      "with-button-close": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_c('c-image', {
          attrs: {
            "height": "120px",
            "object-fit": "cover",
            "src": require('@/assets/icon-confirm-update.svg')
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "justify-content": "center",
            "padding": "30px 24px",
            "text-align": "center"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "18px",
            "size-desktop": "20px",
            "color": "primary.400"
          }
        }, [_vm._v(" " + _vm._s(_vm.selectedCategory ? 'Apakah anda yakin ingin mengubah kategori?' : 'Apakah anda yakin ingin melakukan penambahan kategori?') + " ")])], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "gap": "20px",
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin-bottom": "24px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "left-svg-icon": require('@/assets/icons/icon-circle-close.svg'),
            "left-svg-icon-color": "#008C81",
            "variant": "outlined",
            "rounded": "1000px",
            "width": "100%",
            "disabled": _vm.isLoadingCategory
          },
          on: {
            "click": _vm.onCloseModalConfirmCategory
          }
        }, [_vm._v(" Batal ")]), _c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-confirm.svg'),
            "right-svg-icon-color": "white",
            "color": "primary.400",
            "rounded": "1000px",
            "width": "100%",
            "is-loading": _vm.isLoadingCategory
          },
          on: {
            "click": _vm.onSubmitModalCategory
          }
        }, [_vm._v(" " + _vm._s(_vm.selectedCategory ? 'Simpan' : 'Tambah') + " ")])], 1)];
      },
      proxy: true
    }])
  }), _c('BaseModal', {
    attrs: {
      "is-open": _vm.isOpenModalConfirmSubCategory,
      "close-on-overlay-click": false,
      "with-button-close": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_c('c-image', {
          attrs: {
            "height": "120px",
            "object-fit": "cover",
            "src": require('@/assets/icon-confirm-update.svg')
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "justify-content": "center",
            "padding": "30px 24px",
            "text-align": "center"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "18px",
            "size-desktop": "20px",
            "color": "primary.400"
          }
        }, [_vm._v(" " + _vm._s(_vm.selectedSubCategory ? 'Apakah anda yakin ingin mengubah sub kategori?' : 'Apakah anda yakin ingin melakukan penambahan sub kategori?') + " ")])], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "gap": "20px",
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin-bottom": "24px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "left-svg-icon": require('@/assets/icons/icon-circle-close.svg'),
            "left-svg-icon-color": "#008C81",
            "variant": "outlined",
            "rounded": "1000px",
            "width": "100%",
            "disabled": _vm.isLoadingCategory
          },
          on: {
            "click": _vm.onCloseModalConfirmSubCategory
          }
        }, [_vm._v(" Batal ")]), _c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-confirm.svg'),
            "right-svg-icon-color": "white",
            "color": "primary.400",
            "rounded": "1000px",
            "width": "100%",
            "is-loading": _vm.isLoadingCategory
          },
          on: {
            "click": _vm.onSubmitModalSubCategory
          }
        }, [_vm._v(" " + _vm._s(_vm.selectedSubCategory ? 'Simpan' : 'Tambah') + " ")])], 1)];
      },
      proxy: true
    }])
  }), _c('BaseModal', {
    attrs: {
      "is-open": _vm.isOpenModalAlert,
      "close-on-overlay-click": false,
      "with-button-close": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_c('c-image', {
          attrs: {
            "height": "160px",
            "object-fit": "cover",
            "src": _vm.alert.image,
            "alt": "image success delete coupon"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "justify-content": "center",
            "padding": "30px 24px"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "16px",
            "size-desktop": "18px",
            "font-weight": "bold",
            "color": "primary.400",
            "text-align": "center"
          }
        }, [_vm._v(" " + _vm._s(_vm.alert.message) + " ")])], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin-bottom": "24px",
            "text-align": "center"
          }
        }, [_c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "max-width": "250px",
            "font-size": "18px"
          },
          on: {
            "click": _vm.onCloseModalAlert
          }
        }, [_vm._v(" Oke ")])], 1)];
      },
      proxy: true
    }])
  }), _c('BaseModal', {
    attrs: {
      "is-open": _vm.isOpenModalConfirmDelete,
      "close-on-overlay-click": false,
      "with-button-close": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_c('c-image', {
          attrs: {
            "height": "120px",
            "object-fit": "cover",
            "src": "https://ik.imagekit.io/dietela/pwa_webp/popup/delete.png?updatedAt=1690890254641",
            "alt": "image confirmation delete coupon"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$selectedCategory, _vm$selectedSubCatego;

        return [_c('c-flex', {
          attrs: {
            "justify-content": "center",
            "padding": "30px 24px",
            "text-align": "center"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "18px",
            "size-desktop": "20px",
            "color": "primary.400"
          }
        }, [_vm._v(" Anda yakin ingin menghapus " + _vm._s(_vm.selectedCategory ? 'kategori' : 'sub-kategori') + " konten “" + _vm._s(_vm.selectedCategory ? (_vm$selectedCategory = _vm.selectedCategory) === null || _vm$selectedCategory === void 0 ? void 0 : _vm$selectedCategory.categoryName : (_vm$selectedSubCatego = _vm.selectedSubCategory) === null || _vm$selectedSubCatego === void 0 ? void 0 : _vm$selectedSubCatego.subCategoryName) + "”? ")])], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "gap": "20px",
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin-bottom": "24px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "left-svg-icon": require('@/assets/icons/icon-circle-close.svg'),
            "left-svg-icon-color": "#008C81",
            "variant": "outlined",
            "rounded": "1000px",
            "width": "100%",
            "disabled": _vm.isLoadingCategory
          },
          on: {
            "click": _vm.onCloseModalDelete
          }
        }, [_vm._v(" Batal ")]), _c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "color": "danger",
            "rounded": "1000px",
            "width": "100%",
            "is-loading": _vm.isLoadingCategory
          },
          on: {
            "click": _vm.onSubmitModalDelete
          }
        }, [_vm._v(" Hapus ")])], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }