var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "w": "100%"
    }
  }, [_vm.types.length > 0 ? _c('c-grid', {
    attrs: {
      "width": "100%",
      "template-columns": ['repeat(3, 1fr)', 'repeat(3, 1fr)'],
      "gap": "4",
      "mb": "1rem"
    }
  }, _vm._l(_vm.types, function (item) {
    return _c('c-flex', {
      key: item.type,
      attrs: {
        "w": "100%",
        "p": "1rem",
        "align-items": "center",
        "justify-content": "center",
        "gap": "1rem",
        "bg": item.type === _vm.type ? 'primary.400' : '#F2F2F2',
        "color": item.type === _vm.type ? '#FFF' : '#111',
        "border-radius": "1rem",
        "cursor": "pointer",
        "box-shadow": "0px 2px 2px 0px #008C8126",
        "_hover": {
          backgroundColor: '#008C81',
          color: '#FFFFFF'
        }
      },
      on: {
        "click": function click($event) {
          return _vm.onClick(item.type);
        }
      }
    }, [_c('c-image', {
      attrs: {
        "src": item.image,
        "alt": "",
        "height": "80px"
      }
    }), _c('BaseText', {
      attrs: {
        "size-mobile": "16px",
        "size-desktop": "20px",
        "font-weight": "700"
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")])], 1);
  }), 1) : _c('c-grid', {
    attrs: {
      "width": "100%",
      "template-columns": ['repeat(3, 1fr)', 'repeat(3, 1fr)'],
      "gap": "4",
      "mb": "1rem"
    }
  }, _vm._l(3, function (item) {
    return _c('c-flex', {
      key: item,
      attrs: {
        "w": "100%",
        "p": "1rem",
        "align-items": "center",
        "justify-content": "center",
        "gap": "1rem",
        "bg": "#F2F2F2",
        "color": "#111",
        "border-radius": "1rem",
        "cursor": "pointer",
        "box-shadow": "0px 2px 2px 0px #008C8126"
      }
    }, [_c('c-box', {
      attrs: {
        "w": "80px",
        "h": "80px"
      }
    }, [_c('vue-skeleton-loader', {
      attrs: {
        "type": "circle",
        "width": "100%",
        "height": "100%",
        "animation": "fade",
        "rounded": ""
      }
    })], 1), _c('c-box', {
      attrs: {
        "w": "100px",
        "h": "24px"
      }
    }, [_c('vue-skeleton-loader', {
      attrs: {
        "type": "rect",
        "width": "100%",
        "height": "100%",
        "animation": "fade",
        "rounded": ""
      }
    })], 1)], 1);
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }