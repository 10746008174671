var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-form-control', {
    attrs: {
      "is-disabled": _vm.isDisabled,
      "is-required": _vm.isRequired,
      "is-invalid": _vm.isInvalid,
      "margin-bottom": _vm.isWithoutMarginBottom ? '0' : _vm.SIZE.other.marginBottom,
      "width": _vm.fullWidth ? '100%' : 'inherit'
    }
  }, [!!_vm.label ? _c('c-form-label', {
    attrs: {
      "font-size": _vm.SIZE.label.fontSize,
      "color": _vm.isInvalid ? 'danger.400' : _vm.value ? 'primary.400' : '#555',
      "font-weight": "400",
      "font-family": "Roboto",
      "padding-left": "8px"
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")]) : _vm._e(), _c('c-select', {
    staticClass: "base-input-select__select",
    attrs: {
      "height": _vm.SIZE.input.height,
      "font-size": _vm.SIZE.input.fontSize,
      "placeholder": _vm.placeholder ? _vm.placeholder : _vm.label,
      "color": _vm.isInvalid ? 'danger.400' : _vm.modelValue ? 'primary.400' : '#A0AEC0',
      "font-weight": "500",
      "focus-border-color": "primary.400",
      "border-radius": "8px"
    },
    model: {
      value: _vm.modelValue,
      callback: function callback($$v) {
        _vm.modelValue = $$v;
      },
      expression: "modelValue"
    }
  }, [_vm.options.length === 0 && _vm.modelValue ? _c('option', {
    key: _vm.modelValue,
    staticStyle: {
      "color": "#555"
    },
    domProps: {
      "value": _vm.modelValue
    }
  }, [_vm._v(" " + _vm._s(_vm.modelValue) + " ")]) : _vm._e(), _vm._l(_vm.options, function (option) {
    return _c('option', {
      key: option.value,
      staticStyle: {
        "color": "#555"
      },
      domProps: {
        "value": option.value
      }
    }, [_vm._v(" " + _vm._s(option.label) + " ")]);
  })], 2), !_vm.isDataEmpty(_vm.invalidText) && _vm.isInvalid ? _c('c-form-helper-text', {
    attrs: {
      "font-size": _vm.SIZE.helperText.fontSize,
      "font-weight": _vm.SIZE.helperText.fontWeight,
      "display": "flex",
      "flex-direction": "column",
      "gap": "8px",
      "color": "#D32737"
    }
  }, _vm._l(_vm.invalidText_, function (invalidTextValue, invalidTextKey) {
    return _c('c-text', {
      key: invalidTextKey
    }, [_vm._v(" ● " + _vm._s(invalidTextValue) + " ")]);
  }), 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }