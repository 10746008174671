<template>
  <c-box w="100%">
    <c-grid
      v-if="types.length > 0"
      width="100%"
      :template-columns="['repeat(3, 1fr)', 'repeat(3, 1fr)']"
      gap="4"
      mb="1rem"
    >
      <c-flex
        v-for="item in types"
        :key="item.type"
        w="100%"
        p="1rem"
        align-items="center"
        justify-content="center"
        gap="1rem"
        :bg="item.type === type ? 'primary.400' : '#F2F2F2'"
        :color="item.type === type ? '#FFF' : '#111'"
        border-radius="1rem"
        cursor="pointer"
        box-shadow="0px 2px 2px 0px #008C8126"
        :_hover="{
          backgroundColor: '#008C81',
          color: '#FFFFFF'
        }"
        @click="onClick(item.type)"
      >
        <c-image
          :src="item.image"
          alt=""
          height="80px"
        />
        <BaseText
          size-mobile="16px"
          size-desktop="20px"
          font-weight="700"
        >
          {{ item.name }}
        </BaseText>
      </c-flex>
    </c-grid>
    <c-grid
      v-else
      width="100%"
      :template-columns="['repeat(3, 1fr)', 'repeat(3, 1fr)']"
      gap="4"
      mb="1rem"
    >
      <c-flex
        v-for="item in 3"
        :key="item"
        w="100%"
        p="1rem"
        align-items="center"
        justify-content="center"
        gap="1rem"
        bg="#F2F2F2"
        color="#111"
        border-radius="1rem"
        cursor="pointer"
        box-shadow="0px 2px 2px 0px #008C8126"
      >
        <c-box
          w="80px"
          h="80px"
        >
          <vue-skeleton-loader
            type="circle"
            width="100%"
            height="100%"
            animation="fade"
            rounded
          />
        </c-box>
        <c-box
          w="100px"
          h="24px"
        >
          <vue-skeleton-loader
            type="rect"
            width="100%"
            height="100%"
            animation="fade"
            rounded
          />
        </c-box>
      </c-flex>
    </c-grid>
  </c-box>
</template>

<script>
import VueSkeletonLoader from 'skeleton-loader-vue'  
import BaseText from '@/components/elements/base-text.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ContentType',
  components: {
    VueSkeletonLoader,
    BaseText,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
  },
  emits: ['click'],
  computed: {
    ...mapGetters({
      types: 'admContentChallenge/types',
    }),
  },
  // mounted() {
  //   if (this.types.length == 0) {
  //     this.getContentTypes()
  //   }
  // },
  methods: {
    ...mapActions({
      getContentTypes: 'admContentChallenge/getContentTypes',
    }),
    onClick(type) {
      this.$emit('click', type)
    },
  },
}
</script>

<style>

</style>